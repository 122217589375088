.menu {
    background-color: #181a1f;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin); 
}

.menu img{
    max-width: 100%;
}